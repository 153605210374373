import React from 'react';
import DashboardIndex from '../../components/dashboard/index';

const DashboardIndexPage = () => {
    return(
        <>
            <DashboardIndex />
        </>
    )
}

export default DashboardIndexPage;