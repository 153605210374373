import React from 'react';
import Login from '../../components/auth/Login/index';

const LoginPage = () => {
    return(
        <>
            <Login />
        </>
    )
}

export default LoginPage;