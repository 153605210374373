import React from 'react';
import Verify from '../../components/auth/verify/index';

const VerifyPage = () => {
    return(
        <>
            <Verify />
        </>
    )
}

export default VerifyPage;