import React from 'react';
import ResetPassword from '../../components/auth/resetPassword/reset';

const ResetPasswordPage = () => {
    return(
        <>
            <ResetPassword />
        </>
    )
}

export default ResetPasswordPage;